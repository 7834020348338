var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"bottom":_vm.notificaciones.y === 'bottom',"color":_vm.notificaciones.color,"left":_vm.notificaciones.x === 'left',"multi-line":_vm.notificaciones.mode === 'multi-line',"right":_vm.notificaciones.x === 'right',"timeout":_vm.notificaciones.timeout,"top":_vm.notificaciones.y === 'top',"vertical":_vm.notificaciones.mode === 'vertical'},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":"","text":""},on:{"click":function($event){_vm.notificaciones.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.notificaciones.snackbar),callback:function ($$v) {_vm.$set(_vm.notificaciones, "snackbar", $$v)},expression:"notificaciones.snackbar"}},[_vm._v(" "+_vm._s(_vm.notificaciones.text)+" ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headers,"items":_vm.lista,"page":_vm.page,"items-per-page":_vm.registrosPorPaginaValue,"loading":_vm.cargandoTabla,"sort-by":"nombre","hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card',[_c('v-card-title',{staticClass:"align-start"},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm.tituloTabla))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.haveSearch)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"loading":_vm.cargando,"small":"","color":"accent"},on:{"click":function($event){_vm.dialogSearch = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilter)+" ")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Filtrar")])]),_c('v-dialog',{attrs:{"scrollable":"","max-width":"700px","persistent":""},model:{value:(_vm.dialogSearch),callback:function ($$v) {_vm.dialogSearch=$$v},expression:"dialogSearch"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.tituloSearch)+" "),_c('v-spacer'),_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFilter))])],1),_c('v-card-text',{staticClass:"py-5",staticStyle:{"height":"300px"}},[_vm._t("filterSearch")],2),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"mx-2",attrs:{"type":"reset","outlined":""},on:{"click":_vm.closeDialogSearch}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.closeDialogSearch(); _vm.$emit('dialogSearch');}}},[_vm._v(" Buscar ")])],1)],1)],1),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([(_vm.haveSearch)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({attrs:{"loading":_vm.cargandoTabla,"small":"","color":"accent"},on:{"click":function($event){_vm.closeDialogSearch(); _vm.$emit('refreshSearch');}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiReload)+" ")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Recargar")])]),(_vm.haveAdd)?_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}):_vm._e(),(_vm.haveAdd)?_c('v-btn',{attrs:{"loading":_vm.cargando,"small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('nuevoform')}}},[_vm._v(" Nuevo ")]):_vm._e()],1)],1)]},proxy:true},_vm._l((_vm.headers.filter(function (header) { return header.hasOwnProperty('formatter'); })),function(header){return {key:("item." + (header.value)),fn:function(ref){
var header = ref.header;
var value = ref.value;
return [_c('span',{domProps:{"innerHTML":_vm._s(header.formatter(value))}})]}}}),_vm._l((Object.keys(_vm.$scopedSlots)),function(slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),(_vm.haveActions)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.haveCopyItem)?_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"loading":_vm.cargandoEditar,"x-small":"","color":"default"},on:{"click":function($event){return _vm.$emit('copyform', item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiContentCopy)+" ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Duplicar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.haveEdit)?_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"loading":_vm.cargandoEditar,"x-small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('editform', item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Editar")])]),(item.status !== 'sending' && _vm.haveDelete)?_c('span',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"loading":_vm.cargandoDelete,"x-small":"","color":"error"},on:{"click":function($event){return _vm.$emit('deleteitem', item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.haveDetail)?_c('v-btn',_vm._g({staticClass:"ma-1",attrs:{"loading":_vm.cargandoDetails,"x-small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('detailitem', item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiInformation)+" ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Detalles")])])]}}:null],null,true)}),(_vm.lista.length > 0)?_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('v-pagination',{attrs:{"circle":true,"length":_vm.longitudMaximaNum,"next-icon":_vm.icons.mdiArrowRight,"prev-icon":_vm.icons.mdiArrowLeft,"page":_vm.cantidadPaginas,"total-visible":_vm.totalVisible},on:{"input":_vm.next},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}),_c('span',{staticClass:"compact-form mt-0 mb-0 pt-0 pb-0",staticStyle:{"width":"140px"}},[_c('v-select',{attrs:{"items":_vm.registrosPorPagina,"placeholder":"Cant. Registros","hide-details":""},on:{"change":_vm.onChangePagesCombo},model:{value:(_vm.registrosPorPaginaValue),callback:function ($$v) {_vm.registrosPorPaginaValue=$$v},expression:"registrosPorPaginaValue"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }