<template>
  <div>
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-data-table
      dense
      :headers="headers"
      :items="lista"
      :page.sync="page"
      :items-per-page="registrosPorPaginaValue"
      :loading="cargandoTabla"
      sort-by="nombre"
      class="elevation-1"
      hide-default-footer
    >
      <template v-slot:top>
        <v-card>
          <v-card-title class="align-start">
            <span class="font-weight-semibold">{{ tituloTabla }}</span>
            <v-spacer></v-spacer>
            <!-- Menu para el buscar -->
            <v-tooltip top>
              <template
                v-if="haveSearch"
                v-slot:activator="{ on, attrs }"
              >
                <v-btn
                  :loading="cargando"
                  small
                  color="accent"
                  v-bind="attrs"
                  v-on="on"
                  @click="dialogSearch = true"
                >
                  <v-icon color="primary">
                    {{ icons.mdiFilter }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Filtrar</span>
            </v-tooltip>
            <v-dialog
              v-model="dialogSearch"
              scrollable
              max-width="700px"
              persistent
            >
              <v-card>
                <v-card-title>
                  {{ tituloSearch }}
                  <v-spacer></v-spacer>
                  <v-icon>{{ icons.mdiFilter }}</v-icon>
                </v-card-title>
                <v-card-text
                  class="py-5"
                  style="height: 300px;"
                >
                  <slot name="filterSearch"></slot>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    type="reset"
                    outlined
                    class="mx-2"
                    @click="closeDialogSearch"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="closeDialogSearch(); $emit('dialogSearch');"
                  >
                    Buscar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-divider
              class="mx-3"
              vertical
            ></v-divider>
            <v-tooltip top>
              <template
                v-if="haveSearch"
                v-slot:activator="{ on, attrs }"
              >
                <v-btn
                  :loading="cargandoTabla"
                  small
                  color="accent"
                  @click="closeDialogSearch(); $emit('refreshSearch');"
                  v-on="on"
                >
                  <v-icon color="primary">
                    {{ icons.mdiReload }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Recargar</span>
            </v-tooltip>
            <v-divider
              v-if="haveAdd"
              class="mx-3"
              vertical
            ></v-divider>
            <v-btn
              v-if="haveAdd"
              :loading="cargando"
              small
              color="primary"
              @click="$emit('nuevoform')"
            >
              Nuevo
            </v-btn>
          </v-card-title>
        </v-card>
      </template>
      <!-- This template looks for headers with formatters and executes them -->
      <template
        v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        <span v-html="header.formatter(value)"></span>
      </template>
      <template
        v-for="slot in Object.keys($scopedSlots)"
        :slot="slot"
        slot-scope="scope"
      >
        <slot
          :name="slot"
          v-bind="scope"
        />
      </template>
      <template
        v-if="haveActions"
        v-slot:item.actions="{ item }"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="haveCopyItem"
              :loading="cargandoEditar"
              class="ma-1"
              x-small
              color="default"
              @click="$emit('copyform', item)"
              v-on="on"
            >
              <v-icon small>
                {{ icons.mdiContentCopy }}
              </v-icon>
            </v-btn>
          </template>
          <span>Duplicar</span>
        </v-tooltip>
        <!-- Editar -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="haveEdit"
              :loading="cargandoEditar"
              class="ma-1"
              x-small
              color="primary"
              @click="$emit('editform', item)"
              v-on="on"
            >
              <v-icon small>
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
          </template>
          <span>Editar</span>
        </v-tooltip>

        <!-- Delete -->
        <span v-if="item.status !== 'sending' && haveDelete">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="cargandoDelete"
                class="ma-1"
                x-small
                color="error"
                @click="$emit('deleteitem', item)"
                v-on="on"
              >
                <v-icon small>
                  {{ icons.mdiDelete }}
                </v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </span>
        <!-- Detalles -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="haveDetail"
              :loading="cargandoDetails"
              class="ma-1"
              x-small
              color="primary"
              @click="$emit('detailitem', item)"
              v-on="on"
            >
              <v-icon small>
                {{ icons.mdiInformation }}
              </v-icon>
            </v-btn>
          </template>
          <span>Detalles</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <div
      v-if="lista.length > 0"
      class="d-flex align-center justify-space-between"
    >
      <v-pagination
        v-model="page"
        :circle="true"
        :length="longitudMaximaNum"
        :next-icon="icons.mdiArrowRight"
        :prev-icon="icons.mdiArrowLeft"
        :page="cantidadPaginas"
        :total-visible="totalVisible"
        @input="next"
      ></v-pagination>
      <span
        class="compact-form mt-0 mb-0 pt-0 pb-0"
        style="width: 140px"
      >
        <v-select
          v-model="registrosPorPaginaValue"
          :items="registrosPorPagina"
          placeholder="Cant. Registros"
          hide-details
          @change="onChangePagesCombo"
        ></v-select>
      </span>
    </div>
  </div>
</template>

<script>
import {
  mdiPencil, mdiDelete, mdiArrowLeft,
  mdiArrowRight, mdiInformation, mdiFilter,
  mdiReload, mdiContentCopy,
} from '@mdi/js'
import { ref, reactive } from '@vue/composition-api'
import HTTP from '@/utils/axios/axios-config-base'
import { useRouter } from '@/utils'

export default {
  props: {
    tituloTabla: {
      type: String,
      require: true,
      default: '',
    },
    headers: {
      type: Array,
      require: true,
      default: () => [],
    },
    url: {
      type: String,
      require: true,
      default: '',
    },
    cargando: {
      type: Boolean,
      require: false,
      default: false,
    },
    haveActions: {
      type: Boolean,
      require: false,
      default: false,
    },
    haveCopyItem: {
      type: Boolean,
      require: false,
      default: false,
    },
    haveAdd: {
      type: Boolean,
      require: false,
      default: false,
    },
    haveEdit: {
      type: Boolean,
      require: false,
      default: false,
    },
    haveDelete: {
      type: Boolean,
      require: false,
      default: false,
    },
    haveDetail: {
      type: Boolean,
      require: false,
      default: false,
    },
    havePagination: {
      type: Boolean,
      require: false,
      default: true,
    },
    haveSearch: {
      type: Boolean,
      require: false,
      default: true,
    },
    search: {
      type: Object,
      require: false,
      default: () => {},
    },
    tituloSearch: {
      type: String,
      require: false,
      default: 'Buscar',
    },
  },
  watch: {
    search(val) {
      this.listar()
    },
  },
  setup(props) {
    const { router } = useRouter()
    const cargandoTabla = ref(false)
    const cargandoEditar = ref(false)
    const cargandoDelete = ref(false)
    const cargandoDetails = ref(false)
    const lista = ref([])

    // paginacion
    const page = ref(1)
    const longitudMaximaNum = ref(0)
    const cantidadPaginas = ref(5)
    const totalVisible = ref(5)
    const registrosPorPaginaValue = ref(50)
    const registrosPorPagina = ref([
      5, 10, 20, 50,
    ])
    if (props.haveActions && props.headers.filter(obj => obj.value === 'actions').length === 0) {
      props.headers.push({
        text: 'Acción', value: 'actions', align: 'center', width: '120', sortable: false,
      })
    }

    // search
    const dialogSearch = ref(false)
    const closeDialogSearch = () => {
      dialogSearch.value = false
    }
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })
    const listar = async (pagina = 1) => {
      if (pagina <= 0) {
        pagina = 1
      }
      cargandoTabla.value = true
      const URL = `${props.url}`
      const paginas = props.havePagination ? { Page: pagina, PageSize: registrosPorPaginaValue.value } : {}
      if (URL) {
        await HTTP.get(URL, {
          params: {
            ...props.search,
            ...paginas,

          // Page: pagina,
          // PageSize: registrosPorPaginaValue.value,
          },
        })
          .then(resp => {
            const data = resp.data.value ? resp.data.value : resp.data
            lista.value = []
            for (let index = 0; index < data.entities.length; index += 1) {
              const obj = {
                ...data.entities[index],
              }
              lista.value.push(obj)
            }

            // para evitar error de slint missin radix parameter
            // var num = parseInt("071", 10);  // 71
            longitudMaximaNum.value = parseInt((data.pagination.total / registrosPorPaginaValue.value), 10)
                + (data.pagination.total % registrosPorPaginaValue.value === 0 ? 0 : 1)
            totalVisible.value = registrosPorPaginaValue.value
            cargandoTabla.value = false
          })
          .catch(error => {
            const { errors, requestId, code } = error.response.data
            notificaciones.color = 'error'
            notificaciones.text = 'Ha ocurrido un error mientras se cargaban los datos'
            notificaciones.snackbar = true
            lista.value = []
            cargandoTabla.value = false
          })
      }
    }
    listar()

    return {
      lista,
      router,

      // loadin
      cargandoTabla,
      cargandoEditar,
      cargandoDelete,
      cargandoDetails,

      // icons
      icons: {
        mdiPencil,
        mdiDelete,
        mdiArrowLeft,
        mdiArrowRight,
        mdiInformation,
        mdiFilter,
        mdiReload,
        mdiContentCopy,
      },

      // paginacion
      page,
      longitudMaximaNum,
      cantidadPaginas,
      totalVisible,
      registrosPorPaginaValue,
      registrosPorPagina,

      // search
      dialogSearch,
      closeDialogSearch,
      notificaciones,
      onChangePagesCombo: () => {
        listar()
      },
      next: () => {
        listar(page.value)
      },
      listar: () => {
        listar()
      },
      mostrarSearch: () => {

      },
    }
  },
}
</script>

<style>

</style>
