<template>
  <div>
    <div class="mb-3">
      <Breadcrumbs :items="items" />
    </div>
    <tablaComponent
      ref="tabla"
      :headers="headers"
      :titulo-tabla="tituloTabla"
      :url="url"
      :cargando="cargando"
      :have-actions="haveActions"
      :have-edit="haveEdit"
      :have-detail="haveDetail"
      :search="search"
      :titulo-search="tituloSearch"
      @editform="editItem($event)"
      @detailitem="detailItem($event)"
      @dialogSearch="cargarSearch()"
      @refreshSearch="refreshSearch()"
    >
      <template v-slot:filterSearch>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="filterFilesSelected"
              :items="filterFiles"
              item-value="id"
              item-text="name"
              outlined
              dense
              chips
              small-chips
              label="Filtrar por"
              multiple
              hide-details
            >
              <template v-slot:selection="data">
                <v-chip
                  class="elevation-2 my-1"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeFilterFilesSelected(data.item)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <!-- Estado -->
          <v-col
            v-if="isStateSelected"
            cols="12"
            md="6"
          >
            <v-select
              v-model="stateSearch"
              :items="listaEstados"
              item-value="id"
              item-text="name"
              label="Estado"
              dense
              outlined
            >
            </v-select>
          </v-col>
          <!-- Fecha de Inicio -->
          <v-col
            v-if="isDateSelected"
            cols="12"
            md="6"
          >
            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="inicioSearch"
                  label="Fecha inicio"
                  placeholder="Fecha inicio"
                  persistent-hint
                  :prepend-inner-icon="icons.mdiCalendar"
                  outlined
                  dense
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="inicioSearch"
                no-title
                @input="menu3 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- Fecha de Fin -->
          <v-col
            v-if="isDateSelected"
            cols="12"
            md="6"
          >
            <v-menu
              v-model="menu4"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="finSearch"
                  label="Fecha fin"
                  placeholder="Fecha fin"
                  persistent-hint
                  :prepend-inner-icon="icons.mdiCalendar"
                  outlined
                  dense
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="finSearch"
                no-title
                @input="menu4 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- ReferenceAddress -->
          <v-col
            v-if="isReferenceAddressSelected"
            cols="12"
          >
            <v-text-field
              v-model="referenceAddressSearch"
              label="Dirección de referencia"
              outlined
              dense
              placeholder="Buscar por dirección de referencia"
              hide-details
            ></v-text-field>
          </v-col>
          <!-- RequestNumber -->
          <v-col
            v-if="isRequestNumberSelected"
            cols="12"
          >
            <v-text-field
              v-model="requestNumberSearch"
              label="Número de respuesta"
              outlined
              dense
              placeholder="Buscar por número de respuesta"
              hide-details
            ></v-text-field>
          </v-col>
          <!-- SubTypeIncident -->
          <v-col
            v-if="isSubTypeIncidentSelected"
            cols="12"
          >
            <v-text-field
              v-model="subTypeIncidentNameSearch"
              label="Nombre sub tipo de incidente"
              outlined
              dense
              placeholder="Buscar por nombre de sub tipo de incidente"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            v-if="isSubTypeIncidentSelected"
            cols="12"
            md="6"
          >
            <v-select
              v-model="subTypeIncidentIdSearch"
              :items="listaSubTypeIncident"
              item-value="id"
              item-text="name"
              label="Sub tipo de incidente"
              dense
              outlined
            >
            </v-select>
          </v-col>
          <!-- CitizenId -->
          <v-col
            v-if="isCitizenIdSelected"
            cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="citizenIdSearch"
              :items="listaCitizenId"
              item-value="id"
              :item-text="getItemText"
              outlined
              dense
              label="Cliente"
              hide-details
            >
            </v-autocomplete>
            <!-- <v-select
              v-model="citizenIdSearch"
              :items="listaCitizenId"
              item-value="id"
              item-text="userName"
              label="Ciudadano"
              dense
              outlined
            >
            </v-select> -->
          </v-col>
        </v-row>
      </template>
      <!-- <template v-slot:item.image="{ item }">
        <v-img
          contain
          max-height="40"
          max-width="40"
          :src="item.image"
          class="me-3"
        ></v-img>
      </template> -->
      <template v-slot:item.stateIncident="{ item } ">
        <v-chip
          small
          class="ma-2 elevation-1"
          :color="(item.stateIncident === 'CANCELLED' || item.stateIncident === 'SUSPENSION')?'error':(item.stateIncident === 'PENDING'?'warning':'success')"
          text-color="white"
        >
          {{
            listaEstados.filter((i) => { return i.id === item.stateIncident })[0].name
          }}
        </v-chip>
      </template>
    </tablaComponent>
  </div>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import { ref, reactive, computed } from '@vue/composition-api'
import moment from 'moment'
import HTTP from '@/utils/axios/axios-config-base'
import { useRouter } from '@/utils'
import tablaComponent from '@/components/tablaComponent.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  created() {
    if (this.$route.params.notificaciones != null) {
      this.notificaciones.snackbar = this.$route.params.notificaciones.snackbar
      this.notificaciones.text = this.$route.params.notificaciones.text
      this.notificaciones.color = this.$route.params.notificaciones.color
    }
  },
  components: { tablaComponent, Breadcrumbs },
  setup() {
    const { router } = useRouter()
    const tituloTabla = ref('Registro de Incidente')
    const url = ref(`${HTTP.defaults.baseURL}incidents-api/incidents`)
    const cargando = ref(false)
    const haveActions = ref(true)
    const haveEdit = ref(false)
    const haveDetail = ref(true)
    const headers = ref([
      // { text: 'Imagen', align: 'center', sortable: false, value: 'image', },
      { text: 'Código', sortable: false, value: 'code' },
      { text: 'Descripción', value: 'description' },
      { text: 'Dirección', value: 'referenceAddress' },
      {
        text: 'Fecha',
        value: 'date',
        width: '120',
        formatter: x => (x ? moment(x).format('DD-MM-YYYY') : null),
      },
      { text: 'Estado', value: 'stateIncident' },
    ])
    const items = reactive([
      {
        text: 'Inicio',
        disabled: false,
        href: '/',
      },
      {
        text: `${tituloTabla.value}`,
        disabled: true,
        href: '/registro-incidente',
      },
    ])
    const listaEstados = ref([
      { id: 'REGISTER', name: 'REGISTRARSE' },
      { id: 'IN_PROCESS', name: 'EN PROCESO' },
      { id: 'AWAITING_REPLY', name: 'ESPERANDO RESPUESTA' },
      { id: 'CANCELLED', name: 'CANCELADO' },
      { id: 'TERMINATED', name: 'TERMINADO' },
      { id: 'SUSPENSION', name: 'SUSPENSIÓN' },
      { id: 'REJECTION', name: 'RECHAZO' },
      { id: 'DISPATCH_PENDING', name: 'ENVÍO PENDIENTE' },
      { id: 'PRE_ADMISSION', name: 'PRE ADMISION' },
      { id: 'PENDING', name: 'PENDIENTE' },
    ])
    const tabla = ref(null)
    const search = ref({})
    const tituloSearch = ref('Buscar Registro de Incidente')
    const filterFiles = [
      { id: 'state', name: 'Estado' },
      { id: 'rango_fecha', name: 'Rango de Fechas' },
      { id: 'reference_address', name: 'Dirección de referencia' },
      { id: 'request_number', name: 'Número de respuesta' },
      { id: 'sub_type_incident', name: 'Sub tipo de incidente' },
      { id: 'citizen_id', name: 'Cliente' },
    ]
    const filterFilesSelected = ref([])
    const isStateSelected = computed(() => filterFilesSelected.value.includes('state'))
    const stateSearch = ref('')
    const isDateSelected = computed(() => filterFilesSelected.value.includes('rango_fecha'))
    const inicioSearch = ref('')
    const finSearch = ref('')
    const menu3 = ref(false)
    const menu4 = ref(false)
    const isReferenceAddressSelected = computed(() => filterFilesSelected.value.includes('reference_address'))
    const referenceAddressSearch = ref('')
    const isRequestNumberSelected = computed(() => filterFilesSelected.value.includes('request_number'))
    const requestNumberSearch = ref('')
    const isSubTypeIncidentSelected = computed(() => filterFilesSelected.value.includes('sub_type_incident'))
    const subTypeIncidentNameSearch = ref('')
    const subTypeIncidentIdSearch = ref('')
    const listaSubTypeIncident = reactive([])
    const getSubTypeIncident = async () => {
      const URL = `${HTTP.defaults.baseURL}/incidents-api/incidents-types`
      const resp = await HTTP.get(URL)
      if (resp.status === 200) {
        for (let index = 0; index < resp.data.value.entities.length; index += 1) {
          listaSubTypeIncident.push(resp.data.value.entities[index])
        }
      }
    }
    getSubTypeIncident()
    const isCitizenIdSelected = computed(() => filterFilesSelected.value.includes('citizen_id'))
    const citizenIdSearch = ref('')
    const listaCitizenId = reactive([])
    const getCitizenId = async () => {
      const URL = `${HTTP.defaults.baseURL}/identity-api/member`
      const resp = await HTTP.get(URL)
      if (resp.status === 200) {
        const data = resp.data.value ? resp.data.value : resp.data
        for (let index = 0; index < data.entities.length; index += 1) {
          listaCitizenId.push(data.entities[index])
        }
      }
    }
    getCitizenId()

    return {
      headers,
      router,
      tituloTabla,
      listaEstados,
      url,
      cargando,
      haveActions,
      haveEdit,
      haveDetail,
      items,
      tabla,
      search,
      tituloSearch,
      filterFiles,
      filterFilesSelected,
      isStateSelected,
      stateSearch,
      isDateSelected,
      inicioSearch,
      finSearch,
      menu3,
      menu4,
      icons: {
        mdiCalendar,
      },
      isReferenceAddressSelected,
      referenceAddressSearch,
      isRequestNumberSelected,
      requestNumberSearch,
      isSubTypeIncidentSelected,
      subTypeIncidentNameSearch,
      subTypeIncidentIdSearch,
      listaSubTypeIncident,
      isCitizenIdSelected,
      citizenIdSearch,
      listaCitizenId,
      getItemText(item) {
        return `(${item.userName}) ${item.firstName} ${item.lastName}`
      },
      cargarSearch: () => {
        const searchAll = {}
        if (filterFilesSelected.value.includes('state')) {
          if (stateSearch.value !== '') {
            searchAll.StateIncident = stateSearch.value
          }
        }
        if (filterFilesSelected.value.includes('rango_fecha')) {
          if (inicioSearch.value !== '') {
            searchAll.InitialDate = inicioSearch.value
          }
          if (finSearch.value !== '') {
            searchAll.EndDate = finSearch.value
          }
        }
        if (filterFilesSelected.value.includes('reference_address')) {
          if (referenceAddressSearch.value !== '') {
            searchAll.ReferenceAddress = referenceAddressSearch.value
          }
        }
        if (filterFilesSelected.value.includes('request_number')) {
          if (requestNumberSearch.value !== '') {
            searchAll.RequestNumber = requestNumberSearch.value
          }
        }
        if (filterFilesSelected.value.includes('sub_type_incident')) {
          if (subTypeIncidentNameSearch.value !== '') {
            searchAll.SubTypeIncidentName = subTypeIncidentNameSearch.value
          }
          if (subTypeIncidentIdSearch.value !== '') {
            searchAll.SubTypeIncidentId = subTypeIncidentIdSearch.value
          }
        }
        if (filterFilesSelected.value.includes('citizen_id')) {
          if (citizenIdSearch.value !== '') {
            searchAll.CitizenId = citizenIdSearch.value
          }
        }
        search.value = searchAll
      },
      refreshSearch: () => {
        filterFilesSelected.value = []
        search.value = {}
      },
      removeFilterFilesSelected: async item => {
        const index = filterFilesSelected.value.indexOf(item.id)
        if (index >= 0) filterFilesSelected.value.splice(index, 1)
      },
      detailItem: item => {
        cargando.value = true
        setTimeout(() => {
          const items = [
            {
              text: 'Inicio',
              disabled: false,
              href: '/',
            },
            {
              text: 'Registro de Incidente',
              disabled: false,
              href: '/registro-incidente',
            },
            {
              text: 'Detalle del Incidente',
              disabled: true,
              href: '/registro-incidente/detail',
            },
          ]
          router.push(
            {
              name: 'registro_incidente_detail',
              params: {
                obj: item,
                formTitulo: 'Detalle del Incidente',
                sendForm: false,
                items,
                listaEstados,
              },
            },
          )
          cargando.value = false
        }, 500)
      },
      editItem: item => {
        cargando.value = true
        setTimeout(() => {
          const items = [
            {
              text: 'Inicio',
              disabled: false,
              href: '/',
            },
            {
              text: 'Registro de Incidente',
              disabled: false,
              href: '/registro-incidente',
            },
            {
              text: 'Editar Incidente',
              disabled: true,
              href: '/registro-incidente/detail',
            },
          ]
          router.push(
            {
              name: 'registro_incidente_form',
              params: {
                obj: item,
                formTitulo: 'Editar Incidente',
                sendForm: true,
                items,
                listaEstados,
              },
            },
          )
          cargando.value = false
        }, 500)
      },
    }
  },
}
</script>

<style>

</style>
